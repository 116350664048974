<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Tags <span>{{ isEdit ? 'Edit' : 'Create' }} Tag</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="edit-form-tag">
                            <div class="group_item">
                                <label class="input_label">Tag Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: New Tag" rules="required" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button v-if="isEdit" :disabled="loader" type="button" class="btn danger_btn" @click="handleDeleteTag">Delete</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Adding' : 'Add' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Edit Tag',

        data () {
            return{
                form: {
                    name: '',
                }
            }
        },

        props: {
            modelValue: Boolean,
            tag: Object,
            isEdit: {
                type: Boolean,
                default: false,
            },
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            loader: state => state.tagModule.tagLoader,
        }),

        methods: {
            ...mapActions({
                createTag: 'tagModule/createTag',
                editTag: 'tagModule/editTag',
                getTags: 'tagModule/getTags',
                deleteTag: 'tagModule/deleteTag',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const tagForm = vm.$refs['edit-form-tag'];

                tagForm.validate().then((result) => {
                    if (result.valid) {
                        if (vm.isEdit) {
                            vm.handleEditTag(tagForm);
                        } else {
                            vm.handleCreateTag(tagForm);
                        }
                    }
                });
            },

            handleCreateTag (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.createTag(vm.form).then((result) => {
                    if (result) {
                        vm.getTags();
                        vm.closeModal();
                    }
                });
            },

            handleEditTag (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.editTag(vm.form).then((result) => {
                    if (result) {
                        vm.getTags();
                        vm.closeModal();
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {};

                if (vm.isEdit) {
                    vm.form.id   = vm.tag.id;
                    vm.form.name = vm.tag.name;
                } else {
                    vm.form.name  = '';
                }
            },

            handleDeleteTag () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this tag`, 'Delete');

                options.preConfirm = function () {
                                        return vm.deleteTag(vm.form).then((result) => {
                                            if (result) {
                                                vm.getTags();

                                                if (vm.$parent.$parent && vm.$parent.tag_id != undefined) {
                                                    vm.$parent.$parent.tag_id = '';
                                                } else {
                                                    vm.$parent.tag_id = '';
                                                }

                                                vm.closeModal();
                                            }
                                        });
                                    };

                Swal.fire(options);
            }
        }
    }
</script>
